import { z } from 'zod'

import { OfferIdSchema } from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectOfferExpiredActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'project_offer_expired',
    z
      .object({
        offerId: OfferIdSchema,
      })
      .merge(ActivityLinkTargets('offer'))
  )
