import { z } from 'zod'

import {
  AgreementTypeSchema,
  AgreementVersionSchema,
} from '../agreement.models.ts'
import { AgreementIdSchema, ProjectIdSchema } from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const AgreementTermsAcceptedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'agreement_terms_accepted',
    z
      .object({
        projectId: ProjectIdSchema,
        agreementId: AgreementIdSchema,
        type: AgreementTypeSchema,
        version: AgreementVersionSchema,
      })
      .merge(ActivityLinkTargets('project'))
  )
