import { z } from 'zod'

import { ProjectStatusSchema } from '../../project.models.ts'
import { ProjectIdSchema } from '../../unique-id.models.ts'
import { ActivityLinkTargets } from '../activity-data.models.ts'

export const ProjectStatusChangedActivityDataSchema = z
  .object({
    projectId: ProjectIdSchema,
    status: ProjectStatusSchema,
  })
  .merge(ActivityLinkTargets('project'))
