import { z } from 'zod'

import { ProjectIdSchema, ProjectServiceIdSchema } from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectServiceAddedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'project_service_added',
    z
      .object({
        projectId: ProjectIdSchema,
        projectServiceId: ProjectServiceIdSchema,
      })
      .merge(ActivityLinkTargets('project', 'service'))
  )
