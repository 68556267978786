import { z } from 'zod'

import { ProjectIdSchema, ProjectServiceIdSchema } from '../unique-id.models.ts'
import { ExternalUserTypeSchema } from '../user.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ServiceNoteAddedActivityDetailsSchema = ActivityTypeAndDataSchema(
  'service_note_added',
  z
    .object({
      projectId: ProjectIdSchema,
      projectServiceId: ProjectServiceIdSchema,
      noteType: ExternalUserTypeSchema,
    })
    .merge(ActivityLinkTargets('project', 'service'))
)
