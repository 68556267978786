import { z } from 'zod'
import { ProjectIdSchema } from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectCreatedActivityDetailsSchema = ActivityTypeAndDataSchema(
  'project_created',
  z
    .object({
      projectId: ProjectIdSchema,
    })
    .merge(ActivityLinkTargets('package'))
)
