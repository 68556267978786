import { z } from 'zod'
import { FileIdSchema, ProjectIdSchema } from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectFileAddedActivityDetailsSchema = ActivityTypeAndDataSchema(
  'project_file_added',
  z
    .object({
      projectId: ProjectIdSchema,
      fileId: FileIdSchema,
    })
    .merge(ActivityLinkTargets('project', 'file'))
)
