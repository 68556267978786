import type { OverrideProperties } from 'type-fest'
import { z } from 'zod'

import type { NonEmptyArray } from '@mntn-dev/utility-types'

import { ModelFilterSchema } from '../model-filter-schema.ts'
import { ActivityDetailsSchema } from './activity-details'
import {
  type ActivityType,
  ActivityTypeSchema,
} from './activity-details/activity-types.ts'
import { NonEmptyStringSchema, TimestampSchema } from './property.models.ts'
import {
  ActivityIdSchema,
  AnyUrnSchema,
  FeedIdSchema,
  ThreadIdSchema,
  UserIdSchema,
} from './unique-id.models.ts'

type InferActivityDetails<
  Activity,
  Type extends ActivityType,
> = Activity extends {
  activityType: Type
}
  ? Activity
  : never

const ActivityTargetSchema = z.object({
  urn: AnyUrnSchema,
  fallbackUrn: AnyUrnSchema.optional(),
})
export type ActivityTarget = z.infer<typeof ActivityTargetSchema>

export const ActivityTitleSchema = NonEmptyStringSchema(256)
export type ActivityTitle = z.infer<typeof ActivityTitleSchema>

/**
 * ActivityDomainSelectModel
 */
export const ActivityDomainSelectModelSchema = z.object({
  activityId: ActivityIdSchema,
  feedId: FeedIdSchema,
  threadId: ThreadIdSchema,
  actorId: UserIdSchema,
  timestamp: TimestampSchema,
  details: ActivityDetailsSchema,
})

export type ActivityDomainSelectModel<
  Type extends ActivityType = ActivityType,
> = OverrideProperties<
  z.infer<typeof ActivityDomainSelectModelSchema>,
  {
    details: InferActivityDetails<
      z.infer<typeof ActivityDomainSelectModelSchema>['details'],
      Type
    >
  }
>

export type ActivityDetails<Type extends ActivityType = ActivityType> =
  ActivityDomainSelectModel<Type>['details']

export type ActivityData<Type extends ActivityType = ActivityType> =
  ActivityDetails<Type>['data']

/**
 * ActivityDomainInsertModel
 */
export const ActivityDomainInsertModelSchema =
  ActivityDomainSelectModelSchema.omit({
    timestamp: true,
  }).partial({
    actorId: true,
    activityId: true,
    threadId: true,
  })

export type ActivityDomainInsertModel<
  Type extends ActivityType = ActivityType,
> = OverrideProperties<
  z.infer<typeof ActivityDomainInsertModelSchema>,
  {
    details: InferActivityDetails<
      z.infer<typeof ActivityDomainInsertModelSchema>['details'],
      Type
    >
  }
>

/**
 * ActivityDomainSelectModelFilters
 */
export const ActivityDomainSelectModelFiltersSchema = z
  .object({
    activityId: ModelFilterSchema(ActivityIdSchema),
    actorId: ModelFilterSchema(UserIdSchema),
    feedId: ModelFilterSchema(FeedIdSchema),
    threadId: ModelFilterSchema(ThreadIdSchema),
    activityType: ModelFilterSchema(ActivityTypeSchema),
  })
  .partial()

export type ActivityDomainSelectModelFilters = z.infer<
  typeof ActivityDomainSelectModelFiltersSchema
>

/**
 * @param types the list of activity types to filter by
 * @returns a filter function that returns true if the activity type is in the list
 */
export const ActivityTypeFilter =
  <Type extends ActivityType>(...types: NonEmptyArray<Type>) =>
  (
    activity: ActivityDomainSelectModel
  ): activity is ActivityDomainSelectModel<Type> =>
    types.includes(activity.details.activityType as Type)

export const isActivityType = <Type extends ActivityType>(
  activity: ActivityDomainSelectModel,
  type: Type
): activity is ActivityDomainSelectModel<Type> =>
  ActivityTypeFilter(type)(activity)

export * from './activity-details'
