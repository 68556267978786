import { z } from 'zod'

import { NoteSchema, ProposalVersionSchema } from '../property.models.ts'
import { ProjectIdSchema, ProjectServiceIdSchema } from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const PreProductionMakerProposalSubmittedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'pre_production_maker_proposal_submitted',
    z
      .object({
        projectId: ProjectIdSchema,
        projectServiceId: ProjectServiceIdSchema,
        version: ProposalVersionSchema,
        note: NoteSchema,
      })
      .merge(ActivityLinkTargets('project', 'service'))
  )
