import { z } from 'zod'

import { NonEmptyStringSchema } from '../property.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const CommentCreatedActivityDetailsSchema = ActivityTypeAndDataSchema(
  'comment_created',
  z
    .object({ text: NonEmptyStringSchema(4096) })
    .merge(ActivityLinkTargets('feed'))
)
