import { z } from 'zod'

import {
  AnyUrnSchema,
  ProjectIdSchema,
  UserIdSchema,
} from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectOfferSentActivityDetailsSchema = ActivityTypeAndDataSchema(
  'project_offer_sent',
  z
    .object({
      projectId: ProjectIdSchema,
      offers: z
        .object({
          urn: AnyUrnSchema,
          acceptorId: UserIdSchema,
        })
        .array(),
    })
    .merge(ActivityLinkTargets('project'))
)
