import { z } from 'zod'

import { ProjectSubmittedStatusSchema } from '../project.models.ts'
import { ProjectIdSchema } from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectSubmittedActivityDetailsSchema = ActivityTypeAndDataSchema(
  'project_submitted',
  z
    .object({
      projectId: ProjectIdSchema,
      status: ProjectSubmittedStatusSchema,
    })
    .merge(ActivityLinkTargets('project'))
)
